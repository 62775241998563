import styles from './index.module.scss';
import {Landing} from "../../layouts/Landing";
import {FiChevronDown} from "react-icons/fi";
import {Box, Button, Container, HStack, Image, Stack, Text, useDisclosure} from "@chakra-ui/react";
import HomeIcon1 from "../../assets/home_icon1.svg";
import HomeIcon2 from "../../assets/home_icon2.svg";
import HomeIcon3 from "../../assets/home_icon3.svg";
import HomeIcon4 from "../../assets/home_icon4.svg";
import Java from "../../assets/products/java.svg";
import OpenLDAP from "../../assets/products/open_ldap.svg";
import Oracle from "../../assets/products/oracle.svg";
import PHP from "../../assets/products/php.svg";
import phpMyAdmin from "../../assets/products/php_my_admin.svg";
import SAP from "../../assets/products/sap.svg";
import Scality from "../../assets/products/scality.svg";
import RedHat from "../../assets/products/red_hat.svg";
import VMWare from "../../assets/products/vmware.svg";
import Proxmox from "../../assets/products/proxmox.svg";
import AWS from "../../assets/products/aws.svg";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Cloud from "../Cloud";
import Compare from "../Compare";
import OnPrem from "../OnPrem";
import {RequestCall} from "../../modules/RequestCall";
import {CookieUtil} from "../../utils/cookie";
import {PurchaseModal, PricingType} from "../../modules/PurchaseModal";
import Scality2 from "../../assets/clients/scality.svg";
import Bloomberg from "../../assets/clients/bloomberg.svg";
import Proximus from "../../assets/clients/proximus.svg";
import Oak from "../../assets/clients/oak.svg";
import Energy from "../../assets/clients/energy.svg";
import Telenet from "../../assets/clients/telenet.svg";
import Hcl from "../../assets/clients/hcl.svg";
import Fednot from "../../assets/clients/fednot.svg";
import Liverpool from "../../assets/clients/liverpool.svg";
import Bics from "../../assets/clients/bics.svg";
import Eurosport from "../../assets/clients/eurosport.svg";
import Orange from "../../assets/clients/orange.svg";
import Argenta from "../../assets/clients/argenta.svg";
import Brugge from "../../assets/clients/brugge.svg";
import Callebaut from "../../assets/clients/callebaut.svg";
import Deceunick from "../../assets/clients/deceunick.svg";
import NLS from "../../assets/clients/nls.svg";
import Panasonic from "../../assets/clients/panasonic.svg";
import VPK from "../../assets/clients/vpk.svg";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import {AutoScroll} from "@splidejs/splide-extension-auto-scroll";

export default function Home() {
    const [pricing, setPricing] = useState({} as any)
    const {isOpen, onOpen, onClose} = useDisclosure()

    useEffect(() => {
        fetch("/api/landing/pricing")
            .then(res => res.json())
            .then((p: PricingType) => {
                let {currency, cloud, onPrem} = p

                let lowestCloud = parseFloat(Object.values(cloud).filter(p => !!p).reduce((a, b) => a < b ? a : b))
                let lowestOnPrem = parseFloat(Object.values(onPrem).filter(p => !!p).reduce((a, b) => a < b ? a : b))

                setPricing({
                    lowestCloud: CookieUtil.formatCurrency(lowestCloud, currency),
                    lowestOnPrem: CookieUtil.formatCurrency(lowestOnPrem, currency),
                    other: p
                })
            })
    }, [])

    return (
        <Landing
            extendedHeader={
                <div className={styles.landing} style={{position: "relative", overflowX: "hidden"}}>
                    <div className={styles.textContainer}>
                        <h1>Upgrade-as-a-Service for RHEL 7 and CentOS 7</h1>
                        <Box>
                            <HStack justifyContent={"center"} flexWrap={"wrap"}>
                                <PurchaseModal isOpen={isOpen} onClose={onClose}/>
                                <button onClick={onOpen}>Buy Now</button>
                                <Link style={{marginInline: "initial"}} to={"/#compare"}>
                                    <button>Compare</button>
                                </Link>
                            </HStack>
                            <a className={styles.chevronDown} href={"#about"}><FiChevronDown size={50}/></a>
                        </Box>
                        <Box position={"absolute"}
                             bottom={0}
                             maxW={"100vw"}
                             left={"50%"}
                             transform={"translateX(-50%)"}
                        >
                            <Box position={"relative"}>
                                <h3 style={{
                                    color: "white",
                                    position: "absolute",
                                    fontWeight: 600,
                                    fontSize: "1.1em",
                                    zIndex: 5,
                                    left: "50%",
                                    transform: "translateX(-50%)",
                                }}>Our Clients</h3>

                                <Box style={{
                                    /*backdropFilter: "blur(10px)",
                                    backgroundColor: "rgba(255, 255, 255, 0.7)",*/
                                    width: "100vw",
                                }}>
                                    <div style={{
                                        mask: "linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 15%, rgba(0,0,0,1) 85%, rgba(0,0,0,0) 100%)"
                                    }}>
                                        <Splide aria-label="Our Clients" extensions={{AutoScroll}} options={{
                                            autoWidth: true,
                                            type: 'loop',
                                            pagination: false,
                                            arrows: false,
                                            autoHeight: true,
                                            focus: 'center',
                                            fade: true,
                                            autoScroll: {
                                                speed: 1
                                            }
                                        }}
                                        >
                                            {
                                                [
                                                    [Scality2, "Scality"],
                                                    [Bloomberg, "Bloomberg"],
                                                    [Proximus, "Proximus"],
                                                    [Oak, "Oak Ridge National Laboratory"],
                                                    [Energy, "U.S. Departement of Energy"],
                                                    [Telenet, "Telenet"],
                                                    [Hcl, "Hcl"],
                                                    [Fednot, "Fednot"],
                                                    [Liverpool, "Liverpool John Moores University"],
                                                    [Bics, "Bics"],
                                                    [Eurosport, "Eurosport"],
                                                    [Orange, "Orange"],
                                                    [Argenta, "Argenta"],
                                                    [Brugge, "Brugge"],
                                                    [Callebaut, "Callebaut"],
                                                    [Deceunick, "Deceunick"],
                                                    [NLS, "NLS"],
                                                    [Panasonic, "Panasonic"],
                                                    [VPK, "VPK"],
                                                ].map(([src, alt]) => (
                                                    <SplideSlide key={alt}>
                                                        <Image height={32} src={src} alt={alt}></Image>
                                                    </SplideSlide>
                                                ))
                                            }
                                        </Splide>
                                    </div>
                                </Box>
                            </Box>
                        </Box>
                    </div>
                </div>
            }
            footer={
                <footer className={"landing-footer"}
                        style={{textAlign: "center", backgroundColor: "#002d4b", color: "white", padding: "2em 0"}}>
                    <p>© 2022-2024 Linux Belgium BV</p><br/>
                    <p style={{fontSize: "0.8em"}}>Red Hat Enterprise Linux and CentOS are registered trademarks of Red
                        Hat Inc. Upgrade as a Service, the UaaS abbreviation,</p>
                    <p style={{fontSize: "0.8em"}}>Project78 and its logos are trademarks of Linux Belgium B.V. Linux is
                        a registered trademark of Linus Torvalds.</p>
                </footer>
            }
        >
            <section id={"about"} style={{minHeight: "100vh"}}>
                <Container textAlign={"center"} maxW={"container.xl"} paddingY={100}>
                    <Stack spacing={20}>
                        <Text fontSize={{
                            base: "2xl",
                            md: "3xl",
                        }}>
                            Imagine you can upgrade your RHEL 7 or CentOS 7 servers with 1 command to RHEL 8 or Rocky
                            Linux 8, in 25 minutes.
                            No reinstallation needed, all your data, all your software, all your configurations, in
                            place.
                            95% of your servers require no further action, just a validation of the running
                            applications.
                        </Text>
                        <HStack
                            justifyContent={"space-evenly"}
                            gap={{
                                base: "5em",
                                md: "2em",
                            }}
                            alignItems={{
                                base: "center",
                                sm: "flex-start",
                            }}
                            flexDirection={{
                                base: "column",
                                sm: "row",
                            }}>
                            <Box width={200}>
                                <Image display={"inline-block"} src={HomeIcon1}></Image>
                                <br/>
                                <br/>
                                <b>Stream your upgrade - available right now - from 1 server*</b>
                            </Box>
                            <Box width={200}>
                                <Image display={"inline-block"} src={HomeIcon2}></Image>
                                <br/>
                                <br/>
                                <b>Can run in parallel - up to 100 servers at once.</b>
                            </Box>
                            <Box width={200}>
                                <Image display={"inline-block"} src={HomeIcon3}></Image>
                                <br/>
                                <br/>
                                <b>Purchase your own upgrade server - starting from 200 servers</b>
                            </Box>
                            <Box width={200}>
                                <Image display={"inline-block"} src={HomeIcon4}></Image>
                                <br/>
                                <br/>
                                <b>Guaranteed seamless upgrade for 100% of OS and 95% of application migrations without
                                    intervention.</b>
                            </Box>
                        </HStack>
                        <HStack justifyContent={"center"} flexWrap={"wrap"} gap={"20px"}>
                            <Link to={"/#compare"}>
                                <Button
                                    backgroundColor={"#002D4B"}
                                    color={"white"}
                                    paddingX={10}
                                    paddingY={6}
                                >
                                    Compare features
                                </Button>
                            </Link>
                            <RequestCall/>
                        </HStack>
                        <Text>*Only requirement: your servers need to reach the update server using https (port
                            443).</Text>
                        <br/>
                        <Text>Both products fully support:</Text>
                        <HStack mx={"auto !important"} flexWrap={"wrap"} display={"inline-flex"}
                                justifyContent={"space-evenly"} gap={10} maxW={"4xl"} mb={10}>
                            <Image height={8} src={RedHat} alt={"Red Hat"}></Image>
                            <Image height={8} src={SAP} alt={"SAP"}></Image>
                            <Image height={16} src={OpenLDAP} alt={"OpenLDAP"}></Image>
                            <Image height={8} src={PHP} alt={"PHP"}></Image>
                            <Image height={20} src={Java} alt={"Java"}></Image>
                            <Image height={6} src={phpMyAdmin} alt={"phpMyAdmin"}></Image>
                            <Image height={6} src={Oracle} alt={"Oracle"}></Image>
                            {false && <Image height={8} src={Scality} alt={"Scality"}></Image>}
                            <Image height={6} src={Proxmox} alt={"Proxmox"}></Image>
                            <Image height={10} src={AWS} alt={"AWS"}></Image>
                            <Image height={6} src={VMWare} alt={"VMWare"}></Image>
                        </HStack>
                    </Stack>
                </Container>
            </section>
            <section id={"cloud"} style={{minHeight: "100vh"}}>
                <Cloud lowest={pricing?.lowestCloud}></Cloud>
            </section>
            <section id={"on-premise"} style={{minHeight: "100vh"}}>
                <OnPrem lowest={pricing?.lowestOnPrem}></OnPrem>
            </section>
            <section id={"clients"} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>

            </section>
            <section id={"compare"} style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Compare lowestCloud={pricing?.lowestCloud} lowestOnPrem={pricing?.lowestOnPrem}></Compare>
            </section>
        </Landing>
    );
}
